$primary-color: #009c82;
$layout-spacing: 0.8rem;
$unit-1: 0.8rem;
$unit-2: 1.6rem;

@import "node_modules/spectre.css/src/spectre";
@import "node_modules/spectre.css/src/spectre-exp";

.app-container {
    width: 90vw;
    max-width: 1050px;
    margin: auto;
}

header {
    background-color: $primary-color;
    margin-bottom: 3em;
}

.attribution {
    font-size: 0.6em;
}

.side-info {
    display: flex;
}
.side-info > *.info {
    color: white;
    background-color: $primary-color;
}
.side-info > * {
    padding: 20px;
    flex: 1;
}

.icon-success {
    color: $primary-color;
}
.icon-warn {
    color: $error-color;
}
.dialog {
    margin: 20px 0 10px 0;
    padding: 10px;
}
.info-dialog {
    background-color: rgba(255, 183, 0, 0.8);
    border: 1px solid $warning-color;
}
.warn-dialog {
    background-color: rgba(232, 86, 0, 0.8);
    border: 1px solid $error-color;
    color: white;
}
.about-you .col-oneline {
    margin: 10px 0;
}
.battery-percentage {
    display: inline-block; 
    text-align: center;
    
    span {
        margin-right: 8px;
        position: relative;
        bottom: 5px;
    }
}

.videos.proctoring {
    left: 0;
    right: 0;
    height: 100vh;
    background-color: black;
    position: absolute;
}

.videos.proctoring .controls {
    height: 5vh;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: $primary-color;
    font-weight: bold;
}

.videos.proctoring .controls progress {
    height: 10px;
}

.videos.proctoring .controls svg {
    width: 3vh;
    height: 3vh;
    stroke: $primary-color;
    cursor: pointer;
}

.videos.proctoring video {
    position: absolute;
}

.videos.proctoring video.pos0 {
    top: 5%;
    left: 0;
    width: 100%;
    z-index: 1;
    max-height: 95%;
    max-width: 100%;
}

.videos.proctoring video.pos1 {
    bottom: 0;
    left: 0;
    height: 33%;
    z-index: 2;
}

.videos.proctoring video.pos2 {
    bottom: 0;
    right: 0;
    height: 33%;
    z-index: 2;
}


video.preview {
    height: 150px;
    width: auto;
    object-fit: contain;
    background-color: black;
    box-shadow: 0.3em 0 0.6em rgba(0,0,0,0.5);
}

video.check-preview {
    height: 200px;
    width: auto;
    margin-top: 45px;
    object-fit: contain;
    background-color: black;
    box-shadow: 0.3em 0 0.6em rgba(0,0,0,0.5);
}

.camera-category-header {
    padding-top: 6px;
    padding-bottom: 6px;
}
.camera-warn {
    border-bottom: solid 1px $error-color;
    color: $error-color;
}
.camera-success {
    border-bottom: solid 1px $primary-color;
    color: $primary-color;
}
.camera-content {
    padding: 15px 0;
    
    video.preview {
        height: 150px;
    }
}
.battery {
    transform: rotate(90deg);  
}
.switch-recording input:checked + .form-icon {
    background-color: $error-color !important;
    border-color: $error-color !important;
}
.explainer-image {
    width: 300px;
    margin-left: 120px;
}

.accordion .accordion-header {
    border-bottom: solid 1px #dadee4;
    padding-left: 0;
}

.next-button {
    @extend .btn;
    @extend .btn-primary;
    margin: 2em 0 0 auto;
    display: block;
}

.icon {
    cursor: pointer;
    vertical-align: middle;
}

.icon svg {
    fill: currentColor;
}

p-0 {
    padding: 0;
}

th {
    text-align: left;
    vertical-align: top;
    width: 130px;
}


.logo {
    fill: white;
    height: 112px;
}


.navbar .navbar-section {
    flex: 0 1;
}


// The below is copied and modified from spectre sources, because:
// - The layout was broken in two ways.
// - We needed to change colors.
$step-color: #ddd;

.xstep {
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    margin: $unit-1 0;
    width: 100%;
    
    .step-item {
        flex: 1 1 0;
        margin-top: 0;
        min-height: 1rem;
        text-align: center;
        position: relative;
        
        &::before {
            background: $step-color;
            content: "";
            height: 2px;
            left: 0;
            right: 0;
            position: absolute;
            top: 9px;
        }
        
        &:first-child::before {
            left: 50%;
        }
        
        &:last-child::before {
            right: 50%;
        }  
        
        a {
            color: $step-color;
            display: inline-block;
            padding: 20px 10px 0;
            text-decoration: none;
            
            &::before {
                background: $primary-color;
                border: $border-width-lg solid $step-color;
                border-radius: 50%;
                content: "";
                display: block;
                height: $unit-3;
                left: 50%;
                position: absolute;
                top: 0.2rem;
                transform: translateX(-50%);
                width: $unit-3;
                z-index: $zindex-0;
            }
        }
        
        &.active {
            a {
                color: white;
                
                &::before {
                    background: white;
                    border: $border-width-lg solid white;
                }
            }
            
            & ~ .step-item {
                &::before {
                    background: $border-color;
                }
                
                a {
                    color: $gray-color;
                    
                    &::before {
                        background: $border-color;
                    }
                }
            }
        }
    }
}





.buttons{
    display: flex;
    justify-content: flex-end;
    * {
        margin-right: 1em;
    }
    *:last-child {
        margin-right: 0;
    }
}

.navbar {
    align-items: center;
}

.navbar-section.flag-help {
    flex: 0 0;
    display: block;
}

.flags {
    display: flex;
    margin-bottom: 8%;
}

.flags > svg { 
    border-radius: 3px;
    opacity: 0.8;
    filter: saturate(0.5);
    cursor: pointer;
    width: 46%;
}

.flags > .spacer {
    width: 8%;
}

.flags > svg.selected { 
    opacity: 1;
    filter: none;
}

.flag-help .btn {
    padding: 0.4rem;
    height: auto;
    line-height: 1;
}

.card {
    border: 0;
    box-shadow: 0 .25rem 1rem rgba(48,55,66,.15);
}